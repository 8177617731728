<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>医患管理</el-breadcrumb-item>
      <el-breadcrumb-item>注册患者</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <!-- 是否设置标签： -->
      <el-select
        v-model="select"
        placeholder="是否设置标签"
        size="small"
        clearable
        @clear="getcust(1)"
        style="width: 180px; margin-right: 20px"
      >
        <el-option
          v-for="item in istag"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="custtel"
        size="small"
        placeholder="请输入电话号"
        style="width: 150px"
        clearable
      ></el-input>
      <el-button
        type="primary"
        style="margin-left: 10px"
        size="small"
        @click="query"
        >查询</el-button
      >
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="用户id"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column label="电话" prop="tel"></el-table-column>
      <el-table-column label="年龄" prop="age"></el-table-column>
      <el-table-column label="性别" prop="sex"></el-table-column>
      <el-table-column label="疾病" prop="ill"></el-table-column>
      <!-- <el-table-column label="所属医生" prop="doc_name"></el-table-column> -->
      <!-- <el-table-column label="疾病描述" prop="ill_desc"></el-table-column> -->
      <!-- <el-table-column label="是否治疗过" prop="is_cure">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_cure == 0">否</span>
                    <span v-else>是</span>
                </template>
            </el-table-column> -->
      <!-- <el-table-column label="治疗状态" prop="cure_status">
                <template slot-scope="scope">
                    <div class="opt doc">
                        <span v-if="scope.row.cure_status == 0" style="color: #409EFF;">匹配中</span>
                        <span v-if="scope.row.cure_status == 1" style="color: #E6A23C">治疗中</span>
                        <span v-if="scope.row.cure_status == 2" style="color: #67C23A;">已治愈</span>
                    </div>
                </template>
            </el-table-column> -->
      <el-table-column>
        <template slot-scope="scope" width="150px">
          <div class="opt doc">
            <!-- <a v-if="scope.row.ill_lib_id" @click="del(scope.row)">解除绑定</a>
                        <a v-else @click="addill(scope.row)">绑定疾病</a>
                        <a @click="toDetail(scope.row)">查看</a> -->
            <p>
              <el-button type="primary" size="small" @click="setbq(scope.row)"
                >设置标签</el-button
              >
            </p>
            <p>
              <el-button
                type="success"
                size="small"
                @click="lookfootprint(scope.row)"
                >浏览足迹</el-button
              >
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
    <el-dialog
      class="tcform"
      :title="tctitle"
      :visible.sync="dialogFormVisible"
    >
      <el-form label-width="170px">
        <el-form-item label="疾病列表" prop="jb">
          <el-select
            filterable
            class="zzks"
            v-model="jb"
            placeholder="请选择疾病"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            zz = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
    <powersettag :settag.sync="settag" :tagId="tagId"></powersettag>
  </div>
</template>
<script>
import powersettag from "../components/powersettag.vue";
export default {
  inject: ["reload"],
  components: {
    powersettag,
  },
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      select: "",
      options: [],
      dialogFormVisible: false,
      tctitle: "绑定疾病",
      jb: "",
      item: "",
      istag: [
        { id: 0, name: "未设置" },
        { id: 1, name: "已设置" },
      ],
      settag: false,
      tagId: "",
      custtel: "",
      footId: "",
    };
  },
  mounted() {
    this.getcust(1);
    this.getill();
  },
  methods: {
    getcust(page) {
      // this.axios.get("/gu/get_gu_ill_lib_cust_by_ill?page=" + page + "&size=" + this.PageSize)
      //     .then((res) => {
      //         if (res.data.code == 0) {
      //             // console.log(res)
      //             this.totalCount = res.data.count;
      //             this.list = res.data.result;
      //         } else {
      //             console.log(res)
      //         }
      //     })
      //     .catch((err) => {
      //         console.log(err);
      //     });
      var tag = "";
      this.list = [];
      if (this.custtel == "") {
        if (this.select == "") {
          tag = "all";
        } else {
          tag = this.select;
        }
        this.axios
          .get(
            "/gu/get_gu_custs?page=" +
              page +
              "&size=" +
              this.PageSize +
              "&is_tag=" +
              tag
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.totalCount = res.data.count;
              this.list = res.data.result;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.axios
          .get("/gu/get_gu_cust_by_tel?tel=" + this.custtel)
          .then((res) => {
            if (res.data.code == 0) {
              this.list.push(res.data.result);
              this.totalCount = 1;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getill() {
      this.axios
        .get("/gu/get_gu_ill_lib")
        .then((res) => {
          if (res.data.code == 0) {
            this.options = res.data.result;
            // this.options.unshift({ id: '-1', name: '无' });
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getcust(currentPage);
    },
    addill(row) {
      this.dialogFormVisible = true;
      this.item = row;
    },
    sub() {
      this.axios
        .post(
          "/gu/insert_gu_ill_lib_cust",
          this.qs.stringify({ cust_id: this.item.id, ill_lib_id: this.jb })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
            this.reload();
          } else {
            this.$message(rew.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del(row) {
      this.$confirm("此操作将解除绑定, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/gu/delete_gu_ill_lib_cust",
              this.qs.stringify({ cust_id: row.id, ill_lib_id: row.ill_lib_id })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "解除绑定成功",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message(rew.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解除绑定",
          });
        });
    },
    query() {
      // if (this.select == -1) {
      //     this.select = '';
      // }
      // this.axios.get("/gu/get_gu_ill_lib_cust_by_ill?page=" + 1 + "&size=" + this.PageSize + "&ill_lib_id=" + this.select)
      //     .then((res) => {
      //         if (res.data.code == 0) {
      //             this.totalCount = res.data.result.length;
      //             this.list = res.data.result;
      //         } else if (res.data.code == -1) {
      //             this.totalCount = 0;
      //             this.list = [];
      //         } else {
      //             console.log(res)
      //         }
      //     })
      //     .catch((err) => {
      //         console.log(err);
      //     });
      this.getcust(1);
    },
    toDetail(row) {
      this.$router.push({ path: "/patientDetail", query: { id: row.id } });
    },
    setbq(row) {
      this.settag = true;
      this.tagId = row.id;
    },
    lookfootprint(row) {
      this.$router.push({
        path: "/patientManage/footprint",
        query: { id: row.id },
      });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.zzks {
  float: left;
}

.tcform .el-dialog {
  width: 40%;
}
</style>
